import axios from 'axios'
import merge from 'lodash.merge'
import consola from 'consola'
import {PLURALIZATION_KEYS} from '@/assets/js/constants'

// strapi-specificum with same paramaters in one query
const baseurl = useRuntimeConfig().public.TRANSLATION_CMS_URL || useRuntimeConfig().public.CMS_URL || 'https://cms-dev.dev.fst.com'
const postUser = useRuntimeConfig().public.TRANSLATION_POST_USERNAME || ''
const postPassword = useRuntimeConfig().public.TRANSLATION_POST_PASSWORD || ''

// variables to runtime
let jsonWebtoken = ''

const strapi_axios = axios.create({
  baseURL: baseurl,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Private functions
export async function getJWT(userObject) {
  if (jsonWebtoken !== '') {
    return jsonWebtoken
  } else {
    const {data} = await strapi_axios.post('/auth/local', {identifier: postUser, password: postPassword})
    if (data && data.jwt) {
      jsonWebtoken = 'Bearer ' + data.jwt
      if (userObject) {
        userObject.user = data.user
      }
      return jsonWebtoken
    }
  }
}

/**
 * Will send all data from "translations" having a key in "keys" to strapi
 * @param {array} keys keys to be sent to CMS
 * @param {object} translations translations as i18n-object
 */
export const processNewKeys = async function(keys, translations) {
  if (keys.length === 0) {
    return
  }
  let jwt = null
  let userObj = {}
  try {
    jwt = await getJWT(userObj)
  } catch (e) {
    consola.warn('skipping sync, error occured while logging in. ', e)
    return
  }

  for (let currentKey of keys) {
    let [, entity, key] = /^([^.]+)\.(.*)$/.test(currentKey) ? /^([^.]+)\.(.*)$/.exec(currentKey) : [undefined, null, currentKey]
    let data = {
      Entity: entity,
      Key: key,
      Value: translations[currentKey],
      locale: 'en'
    }

    if (userObj && userObj.user && userObj.user.id) {
      data.updated_by = userObj.user.id
      data.created_by = userObj.user.id
    }

    consola.log('creating translation "' + data.Key + '"')
    let res = await postTranslations(data, jwt)
    if (res.error) {
      consola.error(res.error)
    }
  }
}

export const postTranslations = async function(translations, jwt) {
  try {
    if (!translations.Key) {
      throw new Error('Key must be provided for translations')
    }

    if (!jwt) {
      throw new Error('jwt token must be provided')
    }

    return (await strapi_axios.post(
      '/translations',
      translations,
      {headers: {Authorization: jwt}}
    ))

  } catch (e) {
    return {error: e}
  }
}

export const postLocalization = async function(id, translation, jwt, userObject) {
  if (!translation.locale) {
    throw new Error('locale must be provided for translations')
  }

  if (!id) {
    throw new Error('id must be provided')
  }

  if (!jwt) {
    throw new Error('jwt token must be provided')
  }

  let {locale, Value} = translation

  return (strapi_axios.post(
    `/translations/${id}/localizations`,
    {locale, Value, updated_by: userObject && userObject.id || null},
    {headers: {Authorization: jwt}}
  ))
}

// flattens an object to single level, i.e. { "level1": { "level2": "a" }} becomes {"level1.level2": "a"}
export const flattenTranslations = function(translations) {
  let res = {}
  for (let [key, value] of Object.entries(translations)) {
    if (value !== null && typeof value === 'object') {
      let subObj = flattenTranslations(value)
      for (let [childKey, childValue] of Object.entries(subObj)) {
        res[key + '.' + childKey] = childValue
      }
    } else {
      res[key] = value
    }
  }

  return res
}

// expands object with keys like { "level1.level2": "b" } to { "level1": { "level2": "b" }}
export const expandTranslations = function(translations, textTranslations = {}) {
  let res = {}
  for (let [key, value] of Object.entries(translations)) {
    if (key.indexOf('.') > 0) {
      let [, parentKey, childKey] = /^([^.]+)\.(.*)$/.exec(key)
      if (parentKey === 'attributes' && childKey.indexOf('.') > 0) {
        childKey = childKey.replace('.', '-values.')
      }
      res[parentKey] = res[parentKey] || {}
      let childObject = expandTranslations({[childKey]: value})
      res[parentKey] = merge(res[parentKey], childObject)
    } else {
      if (!PLURALIZATION_KEYS.includes(key) && value !== null && typeof value === 'string' && value.includes('|')) {
        res[key] = value.replaceAll('|', '{\'|\'}')
      } else {
        res[key] = value
      }
    }
  }

  for (let [key, value] of Object.entries(textTranslations)) {
    if (key.indexOf('.') > 0) {
      let [, parentKey, childKey] = /^([^.]+)\.(.*)$/.exec(key)
      if (parentKey === 'attributes' && childKey.indexOf('.') > 0) {
        childKey = childKey.replace('.', '-values.')
      }
      res[parentKey] = res[parentKey] || {}
      let childObject = expandTranslations({}, {[childKey]: value})
      res[parentKey] = merge(res[parentKey], childObject)
    } else {
      if (!PLURALIZATION_KEYS.includes(key) && value !== null && typeof value === 'string' && value.includes('|')) {
        res[key] = value.replaceAll('|', '{\'|\'}')
      } else {
        res[key] = value
      }
    }
  }
  return res
}
